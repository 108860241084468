import Logo from './uoc_logo.png';
import React from 'react';
import styles from './LoginPageLogo.scss';

const LoginPageLogo = () => (
  <div className={styles.loginPageLogo}>
    <img src={Logo} alt="UOC Logo" />
  </div>
);

export default LoginPageLogo;
