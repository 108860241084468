import { gql } from '@apollo/client';
import UserDataFormatter from './helpers/userDataFormatter';
import { SYLLABUS_LESSONS_FRAGMENT } from '../../reducers/dashboard/SyllabusQuery';

const commonAssignmentFields = `
  id
  title
  thumbnailUrl
  annotations {
    id
    text
    title
    teaser
    relatedVideos {
      id
      title
      videoUrl
      resolvedVideo {
        id
        videoUrl
      }
    }
  }
  assignmentType
  assignmentTypeLabel
  courseId
  crewProject {
    id
    crewId
    assignmentId
  }
  description
  dueDate
  dueDays
  disciplines
  externalUrl
  help
  isCrewAssignment
  status
`;

const lessonsFragment = `
          lessons {
            id
            description
            thumbnailUrl
            title
            assignmentsLabel
            assignments {
              ${commonAssignmentFields}
              subAssignments {
                ${commonAssignmentFields}
              }
              subAssignmentsLabel
            }
            supplementals {
              id
              text
              title
            }
            previousLesson {
              id
              thumbnailUrl
              gotoLessonPayload {
                programId
                courseId
                lessonId
                mainAreaId
                tabName
                itemType
                itemId
              }
              title
            }
            nextLesson {
              id
              thumbnailUrl
              gotoLessonPayload {
                programId
                courseId
                lessonId
                mainAreaId
                tabName
                itemType
                itemId
              }
              title
            }
            videos {
              id
              baseUrl
              videoUrl
              title
            }
            info {
              id
            }
          }

`;

const loadUserDataQuery = gql`
  query loadUserData($input: LoadUserDataInput!) {
    userData(input: $input) {
      userPrograms {
        id
        isInstructor
        user
        students {
          id
        }
        projects {
          id
        }
        notes {
          userId
          note
        }
        programInstance
        applicantDetails {
          audio
          camera
          other
          software
          whyFilmmaker
          workUrls
          howDidYouHearAbout
        }
        applicationTimestamp
        application {
          status
          paymentResult
        }
        mentors {
          id
          discipline
        }
      }
      programInstances {
        id
        hasEnded
        isTrial
        disabledDashboardTabs
        isUsingCrewFilesInsteadOfAssets
        pace
        program
        programVersion
        startDate
        weekStartDay
        availabilityDate
        title
        trialWeeks
      }
      programs {
        lastUpdate
      
        courses {
          id
          ${lessonsFragment}
        }
        paces {
          id
          hint
          isModuleBased
          parentId
          parentType
          weeks {
            id
            shortTitle
            lms {
              course
            }
            lessons {
              course
              lesson
            }
          }
        }
        id
        programId
        version
      }
      user {
        id
        address {
          country
          city
          zipPostCode
          stateCountyProvinceRegion
          line1
          line2
          line3
        }
        dateOfBirth
        avatar
        currentUserProgramId
        email
        enrollments {
          id
          applicantDetails {
            audio
            camera
            other
            software
            whyFilmmaker
            workUrls
            howDidYouHearAbout
          }
          application {
            status
            paymentResult
          }
          applicationTimestamp
          isInstructor
          paymentToken
          paymentPlanCode
          program {
            id
            title
          }
          students {
            id
          }
          mentors {
            discipline
          }
          notes {
            userId
            note
          }
          user {
            id
            fullName
          }
          statistics {
            projectCount
            commentCount
            uploadCount
          }
        }
        fullName
        firstName
        lastName
        isAdmin
        isMentor
        isPrivate
        isStaff
        isHidden
        isFreeAccount
        lastTimeOnline
        location
        notificationsLastOpenTime
        privacy
        roleLabel
        shortBio
        smsPhoneNumber
        social {
          facebook
          instagram
          twitter
          vimeo
          website
          youtube
        }
        crews {
          id
          name
          unreadMessageCount
        }
        settings {
          emailNotifications
          smsNotifications
          handIconIndex
          meetingDuration
        }
        introVideo {
          id
          created
          downloadUrl
          fileMimeType
          fileName
          fileSize
          fileType
          finished
          isVisible
          ownerId
          started
          status
          uploadDurationMs
          uploadId
          uploadPath
          uploadType
        }
      }
    }
  }
`;

export const queryUserData = apolloClient => userId =>
  apolloClient
    .query({
      query: loadUserDataQuery,
      variables: { input: { userId } },
      fetchPolicy: 'network-only'
    })
    .then(response => {
      const userDataFormatter = new UserDataFormatter();
      return userDataFormatter.transformPayload(response.data);
    });

export const loadUserData = apolloClient => userId => {
  return queryUserData(apolloClient)(userId);
};
