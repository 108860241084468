import { gql } from '@apollo/client';

export const toggleIsUsingCrewFiles = apolloClient => async programInstanceId => {
  // sc-18800 Disable toggling
  alert('Toggling between assets and crew files has been disabled. ');
  return;

  /*
  return await apolloClient.mutate({
    mutation: gql`
      mutation toggleIsUsingCrewFiles($programInstanceId: ID!) {
        toggleIsUsingCrewFiles(programInstanceId: $programInstanceId)
      }
    `,
    variables: {
      programInstanceId
    },
    refetchQueries: ['getAdminData']
  });
  */
};
