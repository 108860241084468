import { gql } from '@apollo/client';
import { SYLLABUS_LESSONS_FRAGMENT } from '../../reducers/dashboard/SyllabusQuery';

const videoFields = `
  id
  baseUrl
  dashUrl
  description
  duration
  hlsUrl
  spriteUrl
  thumbnailUrl
  title
  type
  url
  videoUrl
  vttUrl
`;

const loadPreStartQuery = gql`
  query loadPreStart($enrolleeId: ID!) {
    preStart(enrolleeId: $enrolleeId) {
      id
      introText
      hint
      parentId
      parentType
      tourVideoUrl
      canNavigate
      tourVideo {
        ${videoFields}
      }
      welcomeVideoUrl
      welcomeVideoThumbnailUrl
      welcomeVideo {
        ${videoFields}
      }
      ${SYLLABUS_LESSONS_FRAGMENT}
    }
  }
`;

export const queryPreStart = apolloClient => enrolleeId =>
  apolloClient
    .query({
      query: loadPreStartQuery,
      variables: { enrolleeId },
      fetchPolicy: 'network-only'
    })
    .then(response => {
      return response.data.preStart;
    });

export const loadPreStart = apolloClient => userProgramId => {
  return queryPreStart(apolloClient)(userProgramId);
};
