// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".OnlyOnComputerAlert--onlyOnComputerDialogOverlay--2RsbOSZC{position:fixed;top:0;right:0;left:0;bottom:0;background-color:rgba(0, 0, 0, .8);overflow:hidden;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;z-index:99}.OnlyOnComputerAlert--onlyOnComputerDialogOverlay--2RsbOSZC .OnlyOnComputerAlert--onlyOnComputerDialog--2VoEpeZ2{z-index:100;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background:#363636;padding:20px 30px;border-radius:4px;position:relative}@media (max-width: 1000px){.OnlyOnComputerAlert--onlyOnComputerDialogOverlay--2RsbOSZC .OnlyOnComputerAlert--onlyOnComputerDialog--2VoEpeZ2{max-width:355px}}@media (max-width: 500px){.OnlyOnComputerAlert--onlyOnComputerDialogOverlay--2RsbOSZC .OnlyOnComputerAlert--onlyOnComputerDialog--2VoEpeZ2{max-width:230px}}@media (max-width: 350px){.OnlyOnComputerAlert--onlyOnComputerDialogOverlay--2RsbOSZC .OnlyOnComputerAlert--onlyOnComputerDialog--2VoEpeZ2{font-size:12px}}.OnlyOnComputerAlert--onlyOnComputerDialogOverlay--2RsbOSZC .OnlyOnComputerAlert--onlyOnComputerDialog--2VoEpeZ2 .OnlyOnComputerAlert--closeButton--7bHG7U7E{padding:3px !important;background:#363636 !important;-ms-flex-item-align:start;align-self:flex-start}.OnlyOnComputerAlert--onlyOnComputerDialogOverlay--2RsbOSZC .OnlyOnComputerAlert--onlyOnComputerDialog--2VoEpeZ2:before{content:'';display:block;position:absolute;background-color:#800000;height:100%;width:10px;left:0;border-bottom-left-radius:4px;border-top-left-radius:4px}\n", ""]);
// Exports
exports.locals = {
	"onlyOnComputerDialogOverlay": "OnlyOnComputerAlert--onlyOnComputerDialogOverlay--2RsbOSZC",
	"onlyOnComputerDialog": "OnlyOnComputerAlert--onlyOnComputerDialog--2VoEpeZ2",
	"closeButton": "OnlyOnComputerAlert--closeButton--7bHG7U7E"
};
module.exports = exports;
