import styles from './SmashcutIframe.scss';
import React, { useRef, useState } from 'react';
import { Rnd } from 'react-rnd';
import Iframe from 'react-iframe';
import {
  actions as popupActions,
  selectors as popupSelectors
} from '../../reducers/popupReducer';
import { hot } from 'react-hot-loader/root';
import { connect } from 'react-redux';
import { SecondaryButton } from './button';
import MarkDownDisplay from './MarkDownDisplay';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';

export const SmashcutIframeComponent = ({
  superTitle,
  title,
  description,
  isOpen,
  src,
  onClose
}) => {
  const rndRef = useRef();
  const [dragging, setDragging] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const calculateDimensions = isExpanded => {
    const sideBarWidth = 380;
    const iframeWidth = isExpanded
      ? window.innerWidth - sideBarWidth - 40
      : 800;

    const gap = 10;
    const width = iframeWidth + sideBarWidth + gap;
    const height = isExpanded ? window.innerHeight - 10 : 600;

    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;
    return { width, height, top, left };
  };

  const { width, height, top, left } = calculateDimensions(isExpanded);

  return (
    <div className={styles.topContainer}>
      {isOpen ? (
        <>
          <div
            className={styles.modalOverlay}
            onClick={e => e.stopPropagation()}
          />
          <Rnd
            default={{
              x: left,
              y: top,
              width,
              height
            }}
            ref={rndRef}
            className={styles.rnd}
            minWidth={1000}
            minHeight={100}
            disableDragging={isExpanded}
            onDragStart={() => setDragging(true)}
            onDragStop={() => setDragging(false)}
          >
            <div className={styles.contentContainer}>
              <Iframe
                url={src}
                width="100%"
                minWidth="700px"
                height="100%"
                className={classnames(
                  dragging && styles.dragging,
                  styles.iframe
                )}
              />
              <div className={styles.sidePanel}>
                <div className={styles.header}>
                  <div className={styles.superTitle}>{superTitle}</div>
                  <div className={styles.buttons}>
                    <SecondaryButton
                      size="small"
                      icon={`fas ${
                        isExpanded ? 'fa-window-minimize' : 'fa-window-maximize'
                      }`}
                      aria-label={isExpanded ? 'Collapse' : 'Expand'}
                      data-tip={isExpanded ? 'Collapse' : 'Expand'}
                      onClick={() => {
                        const newExpanded = !isExpanded;
                        setIsExpanded(newExpanded);

                        const {
                          width,
                          height,
                          top,
                          left
                        } = calculateDimensions(newExpanded);

                        rndRef.current.updateSize({ width, height });
                        rndRef.current.updatePosition({ x: left, y: top });
                      }}
                    />
                    <SecondaryButton
                      size="small"
                      label="Close"
                      aria-label="Close"
                      onClick={onClose}
                    />
                  </div>
                </div>
                <div className={styles.content}>
                  <MarkDownDisplay markDown={title} className={styles.title} />
                  <MarkDownDisplay
                    markDown={description}
                    className={styles.description}
                  />
                </div>
              </div>
            </div>
          </Rnd>
          <ReactTooltip effect="solid" place="top" />
        </>
      ) : null}
    </div>
  );
};

export const POPUP_ID = 'Iframe';
export const showIframe = popupActions.show.call(popupActions, POPUP_ID);
export const hideIframe = popupActions.hide.call(popupActions, POPUP_ID);

export const mapStateToProps = state => {
  const isOpen = popupSelectors.isPopupOpen(state, POPUP_ID);
  const details = popupSelectors.getPopupDetails(state, POPUP_ID) || {};
  return {
    ...details,
    isOpen
  };
};

const mapDispatchToProps = {
  onClose: hideIframe
};

export const SmashcutIframe = hot(
  connect(mapStateToProps, mapDispatchToProps)(SmashcutIframeComponent)
);
