import { gql } from '@apollo/client';

// I've tried graphql "fragments" but then you will need
// interfaces and a build step. To avoid this i'm using
// string interpolation here
const GOTO_LESSON_PAYLOAD_FRAGMENT = `
      gotoLessonPayload {
        programId
        courseId
        lessonId
        mainAreaType
        mainAreaId
        tabName
        itemType
        itemId
        subAssignmentId
        subAssignmentResponseType
      }
    `;

const RESOLVED_VIDEO_FRAGMENT = `
      resolvedVideo {
        id
        baseUrl
        dashUrl
        description
        duration
        hlsUrl
        spriteUrl
        thumbnailUrl
        title
        type
        url
        videoUrl
        vttUrl
      }
    `;

const ANNOTATION_FIELDS_FRAGMENT = `
  id
  relatedVideos {
    id
    title
    videoUrl
    ${RESOLVED_VIDEO_FRAGMENT}
  }
  teaser
  text
  time
  title
`;

const SUBTITLES_FRAGMENT = `
  subTitles {
    id
    label
    lang
    url
  }
`;

const COMMON_ASSIGNMENT_FIELDS_FRAGMENT = `
  id
  annotations {
    ${ANNOTATION_FIELDS_FRAGMENT}
  }
  assignmentType
  assignmentTypeLabel
  description
  disciplines
  dueDate
  dueDays
  externalUrl
  help
  instructions
  instructionsPdfUrl
  isCrewAssignment
  hideSubmitButton
  responseType
  status
  subtitle
  ${SUBTITLES_FRAGMENT}
  thumbnailUrl
  transscriptUrl
  title
  videoUrl
  videoDuration
  ${RESOLVED_VIDEO_FRAGMENT}
  ${GOTO_LESSON_PAYLOAD_FRAGMENT}
  crewProject {
    id
    crewId
    lessonRecord {
      id
      status
    }
  }
`;

const SUB_ASSIGNMENT_FIELDS_FRAGMENT = `
  ${COMMON_ASSIGNMENT_FIELDS_FRAGMENT}
`;

const ASSIGNMENT_FIELDS_FRAGMENT = `
  ${COMMON_ASSIGNMENT_FIELDS_FRAGMENT}
  collapseAssignmentsAfter
  subAssignmentsLabel
  subAssignments {
    ${SUB_ASSIGNMENT_FIELDS_FRAGMENT}
  }
`;

export const SYLLABUS_LESSONS_FRAGMENT = `
  lessons {
    id
    assignmentsLabel
    collapseAssignmentsAfter
    description
    disciplines
    hideLessonMediaFromSyllabus
    thumbnailPosition
    thumbnailUrl
    title
    ${GOTO_LESSON_PAYLOAD_FRAGMENT}
    videos {
      id
      assignmentTypeLabel
      dueDate
      dueDays
      duration
      pdfUrl
      status
      thumbnailUrl
      title
      ${GOTO_LESSON_PAYLOAD_FRAGMENT}
    }
    assignments {
      ${ASSIGNMENT_FIELDS_FRAGMENT}
    }
  }
`;

export const SYLLABUS_QUERY = gql`
  query syllabus($enrolleeId: String!) {
    syllabus(enrolleeId: $enrolleeId) {
      startDate
      assets {
        id
        generation
        name
        originalFileName
        storagePath
      }
      weeks {
        id
        availableFrom
        canNavigate
        endDate
        index
        isModuleBased
        progress
        shortTitle
        startDate
        title
        ${SYLLABUS_LESSONS_FRAGMENT}
      }
    }
  }
`;

export const SYLLABUS_ASSIGNMENT_FRAGMENT = gql`
  fragment a on SyllabusAssignment {
    ${ASSIGNMENT_FIELDS_FRAGMENT}
  }
`;

export const SYLLABUS_SUB_ASSIGNMENT_FRAGMENT = gql`
  fragment a on SyllabusAssignment {
    ${SUB_ASSIGNMENT_FIELDS_FRAGMENT}
  }
`;
