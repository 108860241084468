import {
  gotoLessonPayloadToUrl,
  MAIN_AREA_TYPE,
  makeGotoLessonPayload,
  responseTypeToMainAreaType
} from '../smashcut-client-lib/utils/gotoLessonHelpers';
import { makeVideoMarker } from 'utils/makeVideoMarker';
import {
  getComment,
  getCurrentUser,
  getListOfComments
} from 'smashcut-client-lib/selectors';
import { types as lessonProjectTypes } from './lessonProjectTypes';
import { get } from 'lodash';
import * as userProgramHelper from '../smashcut-client-lib/utils/userProgramHelper';
import { logAndSendError } from '../utils/sentryHelper';

const PREFIX = 'LESSON/';

export const types = {
  CLEAR_CURRENT_LESSON: PREFIX + 'CLEAR_CURRENT_LESSON',
  GOTO_LESSON: PREFIX + 'GOTO_LESSON',
  LAUNCH_LESSON_VIDEO: PREFIX + 'LAUNCH_LESSON_VIDEO',
  LOAD_LESSON_FAILURE: PREFIX + 'LOAD_LESSON_FAILURE',
  LOAD_LESSON_REQUEST: PREFIX + 'LOAD_LESSON_REQUEST',
  LOAD_LESSON_SUCCESS: PREFIX + 'LOAD_LESSON_SUCCESS',
  SELECT_PROJECT: PREFIX + 'SELECT_PROJECT',
  SET_LESSON_SPEC: PREFIX + 'SET_LESSON_SPEC',
  UPDATE_REVIEW_ITEM: PREFIX + 'UPDATE_REVIEW_ITEM',
  USER_PROGRAM_LOAD: PREFIX + 'USER_PROGRAM_LOAD',
  USER_PROGRAM_LOADED: PREFIX + 'USER_PROGRAM_LOADED',
  REMOVE_PROGRAM_DATA: PREFIX + 'REMOVE_PROGRAM_DATA'
};

export const initialState = {
  lessonSpec: {},
  gotoLessonPayload: {},
  lessonOnLoadAction: undefined,
  loading: false
};

export default (state = initialState, action) => {
  let nextState = state;

  switch (action.type) {
    case types.GOTO_LESSON:
      nextState = { ...state };
      nextState.gotoLessonPayload = action.payload;
      nextState.lessonOnLoadAction = action.lessonOnLoadAction;
      break;

    case types.LOAD_LESSON_REQUEST:
      nextState = { ...state };
      nextState.lessonSpec = action.lessonSpec;
      nextState.loading = true;
      break;

    case types.LOAD_LESSON_SUCCESS:
      console.warn(action.type, action);

      nextState = { ...state };
      nextState.data = action.data;
      nextState.error = null;
      nextState.loading = false;
      nextState.action = state.lessonOnLoadAction;
      nextState.lessonOnLoadAction = undefined;
      break;

    case lessonProjectTypes.SCREENPLAY_EDITOR_CLOSE:
    case lessonProjectTypes.REVIEW_EDITOR_CLOSE:
    case lessonProjectTypes.PROJECT_EDITOR_CLOSE:
    case lessonProjectTypes.IMAGE_EDITOR_CLOSE:
      nextState = { ...state };
      nextState.action = undefined;
      break;

    case types.LOAD_LESSON_FAILURE:
      console.warn(action.type, action);
      nextState = { ...state };
      nextState.data = null;
      nextState.lessonSpec = {};
      nextState.error = action.error;
      nextState.loading = false;
      nextState.lessonOnLoadAction = undefined;
      break;

    case types.CLEAR_CURRENT_LESSON:
      console.warn(action.type, action);
      nextState = { ...state };
      nextState.data = null;
      nextState.lessonSpec = {};
      nextState.error = null;
      nextState.loading = false;
      nextState.lessonOnLoadAction = undefined;
      break;

    case types.SET_LESSON_SPEC:
    case types.UPDATE_REVIEW_ITEM:
      console.log(action.type, action);
      nextState = { ...state };
      nextState.lessonSpec = action.lessonSpec;
      break;

    case types.USER_PROGRAM_LOAD:
      nextState = { ...state };
      nextState.userProgram = action.userProgram;
      break;

    case types.USER_PROGRAM_LOADED:
      nextState = { ...state };
      nextState.userProgram = action.userProgram;
      break;
  }

  return nextState;
};

function clearCurrentLesson() {
  return { type: types.CLEAR_CURRENT_LESSON };
}

function setCurrentLesson(params) {
  return (dispatch, getState) => {
    const state = getState();
    if (isSameLesson(params, state.lesson.lessonSpec)) {
      if (reviewItemHasChanged(params, state.lesson.lessonSpec)) {
        dispatch({ type: types.UPDATE_REVIEW_ITEM, lessonSpec: params });
      }
      return 'lesson loaded already';
    }

    dispatch({ type: types.LOAD_LESSON_REQUEST, lessonSpec: params });

    // the actual loading happens in the lesson saga

    // -------------------------------------------------

    function isSameLesson(lessonSpecA, lessonSpecB) {
      return (
        lessonSpecA.programId === lessonSpecB.programId &&
        lessonSpecA.courseId === lessonSpecB.courseId &&
        lessonSpecA.lessonId === lessonSpecB.lessonId
      );
    }

    function reviewItemHasChanged(lessonSpecA, lessonSpecB) {
      return lessonSpecA.mainAreaId !== lessonSpecB.mainAreaId;
    }
  };
}

// FYI: How does the browser history get updated? By the lessonSaga!
function gotoLesson(payload, lessonOnLoadAction) {
  return {
    type: types.GOTO_LESSON,
    payload,
    lessonOnLoadAction
  };
}

function gotoPreviousLesson() {
  return (dispatch, getState) => {
    const state = getState();
    const lessonNav = getLessonNav(state);
    if (
      lessonNav.previousLesson &&
      lessonNav.previousLesson.gotoLessonPayload
    ) {
      dispatch(gotoLesson(lessonNav.previousLesson.gotoLessonPayload));
    }
  };
}

function gotoNextLesson() {
  return (dispatch, getState) => {
    const state = getState();
    const lessonNav = getLessonNav(state);
    if (lessonNav.nextLesson && lessonNav.nextLesson.gotoLessonPayload) {
      dispatch(gotoLesson(lessonNav.nextLesson.gotoLessonPayload));
    }
  };
}

function launchLessonMedia(media, type) {
  return (dispatch, getState) => {
    const state = getState();

    if (media.id === getCurrentMainAreaId(state)) {
      return;
    }

    dispatch({ type: types.LAUNCH_LESSON_VIDEO, video: media });
    const lessonSpec = state.lesson.lessonSpec;
    const payload = makeGotoLessonPayload(
      lessonSpec.programId,
      lessonSpec.courseId,
      lessonSpec.lessonId,
      type,
      media.id,
      'info'
    );
    dispatch(gotoLesson(payload));
  };
}

function gotoSub(item, history) {
  return (dispatch, getState) => {
    const state = getState();
    console.log('gotoSub', item, state.lesson, state);
    const media = getLessonMedia(state)[0] || { type: 'IMAGE', id: '' };
    const lessonSpec = state.lesson.lessonSpec;
    const payload = makeGotoLessonPayload(
      lessonSpec.programId,
      lessonSpec.courseId,
      lessonSpec.lessonId,
      media.type.toLowerCase(),
      media.id,
      'subAssignment',
      item.assignment.id
    );

    dispatch(setLessonSpec(payload, history));
  };
}

function gotoParent(item, history) {
  return (dispatch, getState) => {
    const state = getState();
    console.log('gotoParent', item, state.lesson, state);
    const media = getLessonMedia(state)[0] || { type: 'IMAGE', id: '' };
    const lessonSpec = state.lesson.lessonSpec;
    const payload = makeGotoLessonPayload(
      lessonSpec.programId,
      lessonSpec.courseId,
      lessonSpec.lessonId,
      media.type.toLowerCase(),
      media.id,
      'assignment',
      item.assignment.parentId
    );

    dispatch(setLessonSpec(payload, history));
    dispatch({ type: types.LAUNCH_LESSON_VIDEO, video: media });
  };
}

// use this if you want to change something in the url of the  current
// lesson without reloading it
function setLessonSpec(lessonSpec, history) {
  return (dispatch, getState) => {
    history.replace(gotoLessonPayloadToUrl(lessonSpec));
    dispatch({ type: types.SET_LESSON_SPEC, lessonSpec });
  };
}

function selectAssignment(video, assignmentId, userProgramId) {
  return (dispatch, getState) => {
    const state = getState();
    const lessonSpec = state.lesson.lessonSpec;
    userProgramId = userProgramId || lessonSpec.programId;
    if (
      userProgramId === lessonSpec.programId &&
      video.id === getCurrentMainAreaId(state)
    ) {
      return;
    }

    dispatch({ type: types.LAUNCH_LESSON_VIDEO, video });
    const payload = makeGotoLessonPayload(
      userProgramId,
      lessonSpec.courseId,
      lessonSpec.lessonId,
      MAIN_AREA_TYPE.VIDEO,
      video.id,
      'assignment',
      assignmentId
    );
    dispatch(gotoLesson(payload));
  };
}

function selectProject(project) {
  return (dispatch, getState) => {
    const state = getState();
    console.log('selectProject', project);

    if (project.id === getCurrentMainAreaProjectId(state)) {
      return;
    }

    const projectType = responseTypeToMainAreaType(project.responseType);

    dispatch({ type: types.SELECT_PROJECT, project, projectType });

    const payload = makeGotoLessonPayload(
      project.userProgramId,
      project.courseId,
      project.lessonId,
      projectType,
      project.id,
      project.parentAssignment ? 'subAssignment' : 'assignment',
      project.assignmentId
    );
    dispatch(gotoLesson(payload));
  };
}

const openAssetInWindow = assetUrl => {
  return (dispatch, getState, { api }) => {
    const resolveAsset = userProgramHelper.makeResolveAssetFn(api);
    return resolveAsset(assetUrl)
      .then(url => {
        const win = window.open(url, '_blank');
        win.focus();
      })
      .catch(logAndSendError);
  };
};

export const actions = {
  clearCurrentLesson,
  gotoLesson,
  gotoParent,
  gotoSub,
  gotoPreviousLesson,
  gotoNextLesson,
  launchLessonMedia,
  openAssetInWindow,
  selectProject,
  selectAssignment,
  setCurrentLesson
};

const defaultValueArr = [];
const defaultValueObj = {};

function getLessonSpec(state) {
  return state.lesson.lessonSpec;
}

function isLessonProject(project) {
  return (
    project &&
    project.userProgramId &&
    project.courseId &&
    project.lessonId &&
    project.id
  );
}

function getAnnotationMarkers(state) {
  const video = getCurrentLessonVideo(state);
  if (video && video.annotations) {
    return video.annotations.map(item => {
      return {
        ...item,
        markerType: 'note',
        isAutoShowMarker: true
      };
    });
  } else {
    return defaultValueArr;
  }
}

function getCommentMarkers(state) {
  const commentIds = getListOfComments(state);
  const markers = commentIds.map(id => {
    const comment = getComment(state, id);
    const currentUser = getCurrentUser(state);
    const commentAuthor = comment?.authorUser;

    if (comment && commentAuthor && currentUser) {
      const isCurrentUser = currentUser.id === commentAuthor.id;

      return makeVideoMarker(comment, commentAuthor, isCurrentUser);
    }

    return null;
  });

  return markers.filter(m => m);
}

function getGotoLessonPayload(state) {
  return state.lesson.gotoLessonPayload;
}

function getLessonContent(state) {
  const data = getLessonData(state);
  return {
    ...(data.lesson ? data.lesson.content : {}),
    date: data.lesson && data.lesson.lessonDate
  };
}

function getLessonData(state) {
  return (state.lesson && state.lesson.data) || defaultValueObj;
}

function getLessonAssignments(state) {
  const data = getLessonData(state);
  const result =
    (data && data.lesson && data.lesson.assignments) || defaultValueArr;
  return result;
}

function getLessonAssignment(state, assignmentId) {
  const assignments = getLessonAssignments(state);
  const result = assignments.find(a => a.id === assignmentId);
  return result;
}

function getLessonFilmItems(state) {
  const data = getLessonData(state);
  return (data && data.lesson && data.lesson.films) || defaultValueArr;
}

function getLessonGlossaryItems(state) {
  const data = getLessonData(state);
  return (data && data.lesson && data.lesson.glossary) || defaultValueArr;
}

function getLessonInfo(state) {
  const data = getLessonData(state);
  return data.lesson && data.lesson.info;
}

function getLessonNextLessons(state) {
  const data = getLessonData(state);
  return data.lesson && data.lesson.nextLessons;
}

function getLessonTitle(state) {
  const data = getLessonData(state);
  return {
    title: data.lesson ? data.lesson.info.title : '',
    courseName:
      data.lesson && data.lesson.info.course ? data.lesson.info.course.name : ''
  };
}

// @deprecated use getLessonMedia instead
function getLessonVideos(state) {
  const data = getLessonData(state);
  return (data && data.lesson && data.lesson.videos) || defaultValueArr;
}

function getLessonMedia(state) {
  const data = getLessonData(state);
  return (data && data.lesson && data.lesson.videos) || defaultValueArr;
}

function getCurrentLessonVideo(state) {
  const videoId = getCurrentMainAreaId(state);
  const videos = getLessonVideos(state);
  const v = videos.find(v => v.id === videoId);
  return v;
}

function getCurrentLessonPdf(state) {
  const lessonData = getLessonData(state);
  const mainAreaType = getCurrentMainAreaType(state);
  return mainAreaType === 'pdf' && get(lessonData, 'lesson.videos[0].url');
}

function getCurrentLesson3D(state) {
  const lessonData = getLessonData(state);
  const mainAreaType = getCurrentMainAreaType(state);
  return mainAreaType === 'object3d' && get(lessonData, 'lesson.videos[0].url');
}

function getCurrentLessonMediaImages(state) {
  const lessonData = getLessonData(state);
  const mainAreaType = getCurrentMainAreaType(state);
  return mainAreaType === 'image' && get(lessonData, 'lesson.videos[0].images');
}

function getCurrentMainAreaPdf(state) {
  const lessonData = getLessonData(state);
  const mainAreaType = getCurrentMainAreaType(state);
  const isLessonPdf = mainAreaType === 'pdf';
  if (isLessonPdf) {
    return {
      url: get(lessonData, 'lesson.videos[0].url'),
      shouldSignUrl: false // signed already in loadLessonSaga
    };
  }
  const isScreenplayPdf = get(lessonData, 'screenplay.fileName', '')
    .toLowerCase()
    .endsWith('.pdf');
  if (isScreenplayPdf) {
    return {
      url: get(lessonData, 'screenplay.downloadUrl', ''),
      shouldSignUrl: true
    };
  }
  return {
    url: '',
    shouldSignUrl: false
  };
}

function getCurrentMainAreaType(state) {
  return (
    state.lesson &&
    state.lesson.lessonSpec &&
    state.lesson.lessonSpec.mainAreaType
  );
}

function getCurrentMainAreaId(state) {
  return (
    state.lesson &&
    state.lesson.lessonSpec &&
    state.lesson.lessonSpec.mainAreaId
  );
}

function getCurrentMainAreaProjectId(state) {
  return (
    state.lesson &&
    state.lesson.lessonSpec &&
    state.lesson.lessonSpec.mainAreaId &&
    state.lesson.lessonSpec.mainAreaId.split('!').shift()
  );
}

function getCurrentMainAreaImageIds(state) {
  return (
    state.lesson &&
    state.lesson.lessonSpec &&
    state.lesson.lessonSpec.mainAreaId &&
    state.lesson.lessonSpec.mainAreaId.split('!')
  );
}

function currentVideoIsLessonVideo(state) {
  const currentVideoId = getCurrentMainAreaId(state);
  if (!currentVideoId) {
    return false;
  }
  const lessonVideos = getLessonVideos(state);
  return lessonVideos.find(v => v.id === currentVideoId);
}

function hasReviewItem(state) {
  return !currentVideoIsLessonVideo(state);
}

function getLessonUserProgram(state) {
  return state.lesson && state.lesson.userProgram;
}

function getLessonOnLoadAction(state) {
  return state.lesson?.action || {};
}

function getLessonNav(state) {
  const showNavAtTheEndOfVideo = currentVideoIsLessonVideo(state);

  const data = getLessonData(state);
  const lesson = data.lesson;

  const previousLesson = lesson && lesson.previousLesson;
  const previousLessonNotAvailable = !previousLesson;
  const previousLessonLocked =
    previousLesson && !previousLesson.gotoLessonPayload;
  const previousLessonDisabled =
    previousLessonLocked || previousLessonNotAvailable;

  const nextLesson = lesson && lesson.nextLesson;
  const nextLessonNotAvailable = !nextLesson;
  const nextLessonLocked = nextLesson && !nextLesson.gotoLessonPayload;
  const nextLessonDisabled = nextLessonLocked || nextLessonNotAvailable;
  return {
    previousLesson,
    previousLessonLocked,
    previousLessonDisabled,
    nextLesson,
    nextLessonLocked,
    nextLessonDisabled,
    showNavAtTheEndOfVideo
  };
}

function getLoadingState(state) {
  return state.lesson.loading;
}

export const selectors = {
  currentVideoIsLessonVideo,
  getAnnotationMarkers,
  getCommentMarkers,
  getCurrentLessonPdf,
  getCurrentLessonMediaImages,
  getCurrentLesson3D,
  getCurrentMainAreaPdf,
  getCurrentMainAreaId,
  getCurrentMainAreaImageIds,
  getCurrentMainAreaProjectId,
  getCurrentMainAreaType,
  getGotoLessonPayload,
  getLessonAssignment,
  getLessonAssignments,
  getLessonContent,
  getLessonData,
  getLessonFilmItems,
  getLessonGlossaryItems,
  getLessonInfo,
  getLessonMedia,
  getLessonNav,
  getLessonNextLessons,
  getLessonOnLoadAction,
  getLessonSpec,
  getLessonTitle,
  getLessonUserProgram,
  getLessonVideos,
  hasReviewItem,
  isLessonProject,
  getLoadingState
};
