export const footerLinks = {
  copyrightOwner: 'UOC',
  facebookUrl: 'https://www.facebook.com/SummerUChicago/',
  flickrUrl: '',
  instagramUrl: 'https://www.instagram.com/summeruchicago/',
  mediumUrl: '',
  privacyPolicyUrl: 'https://www.smashcut.com/privacy',
  termsOfServiceUrl: 'https://www.smashcut.com/terms',
  twitterUrl: '',
  vimeoUrl: '',
  youtubeUrl: ''
};

export const getInTouch = `If you have questions, you can get in touch
      with a student advisor by emailing help@smashcut.com or by clicking the
      "Help Chat" button in the header of any Smashcut webpage.`;

export const helpCenterUrl = 'https://intercom.help/smashcut';

export const highlightColor = '#993333';

export const showDisciplines = false;

export const showProgramStartDate = true;

export const useScreenplayForPdfUploads = true;

export const hideCrews = true;

export const showLms = true;

export const blockMobileAccess = false;

export const topNavLinks = {
  theme: 'uchicago',
  blog: {
    blogComponent: false,
    blogUrl: 'https://medium.com/smashcut-film'
  },
  helpCenter: {
    helpCenterKeyMentor: 'help center',
    helpCenterTitleMentor: 'Help Center',
    helpCenterUrlMentor: 'https://intercom.help/smashcut',
    helpCenterKey: 'help center',
    helpCenterTitle: 'Help Center',
    helpCenterUrl: 'https://intercom.help/smashcut'
  },
  availability: {
    availabilityComponent: false
  }
};

export const requiredAccountDetailsFields = {};

export const preStartModules = {};

export const videoMeetingArchivingEnabled = true;
