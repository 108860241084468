'use strict';

import baseConfig from './base';

const awsApi = 'https://uchicago.api.smashcut.com';
const awsWss = 'wss://3g0brly5o1.execute-api.eu-west-1.amazonaws.com/uchicago';

let config = {
  apolloUri: `${awsApi}/graphql`,
  appEnv: 'aws-uc',
  baseUrl: 'https://uchicago.smashcut.com',
  env: 'prod',
  logrocket: 'px1cem/uc-smashcut-web',
  logRocketEnabled: true,
  opentokApiKey: '47015574',
  publicSiteUrl: 'https://www.smashcut.com',
  pushMessagingPublicVapidKey:
    'BGB8CXTRNeJ8CC2tdzKzVTgcFbcsM1-qI7vgpXjXMkBeRXanmBLfydA4AtMM46_OFPwAN4GxIXm_8JPJwklTsJE',
  sentry:
    'https://c245c4c53a6d4b589a4824c460298651@o82327.ingest.sentry.io/179879',
  sentryEnabled: true,
  smashcutApiUrl: awsApi,
  wssUrl: awsWss
};

let mergedConfig = Object.freeze(Object.assign({}, baseConfig, config));
// console.log('Using config: ', mergedConfig)
export default mergedConfig;
