import { actions as relatedVideoActions } from 'reducers/relatedVideoPlayer';
import { actions as uploadActions, UploadCanceledError } from '../uploads';
import { get } from 'lodash';
import { logAndSendError } from 'utils/sentryHelper';
import { makeResolveAssetFn } from 'smashcut-client-lib/utils/userProgramHelper';
import { selectors as sclSelectors } from 'smashcut-client-lib';
import { UPLOAD_TYPE } from 'common/constants';
import { actionType } from 'smashcut-client-lib/constants';
import { resolveContentImage } from '../../common/resolveContentImage';

const PREFIX = 'DASHBOARD/PRE_START';

export const types = {
  LOAD_PRESTART_REQUEST: `${PREFIX}/LOAD_PRESTART_REQUEST`,
  LOAD_PRESTART_SUCCESS: `${PREFIX}/LOAD_PRESTART_SUCCESS`,
  LOAD_PRESTART_FAILURE: `${PREFIX}/LOAD_PRESTART_FAILURE`,
  ADD_INTRO_VIDEO_ERROR: `${PREFIX}/ADD_INTRO_VIDEO_ERROR`,
  CLEAR_INTRO_VIDEO_ERROR: `${PREFIX}/CLEAR_INTRO_VIDEO_ERROR`,
  RESOLVE_TOUR_VIDEO: `${PREFIX}/RESOLVE_TOUR_VIDEO`,
  RESOLVE_WELCOME_VIDEO: `${PREFIX}/RESOLVE_WELCOME_VIDEO`,
  RESOLVE_LESSON_VIDEOS: `${PREFIX}/RESOLVE_LESSON_VIDEOS`
};

const initialState = {
  introVideoError: undefined,
  tourVideo: undefined,
  welcomeVideo: undefined,
  lessons: [],
  preStart: undefined
};

export const preStartReducer = (state = initialState, action) => {
  let nextState = state;
  switch (action.type) {
    case types.LOAD_PRESTART_REQUEST:
    case types.LOAD_PRESTART_FAILURE:
      break;
    case types.LOAD_PRESTART_SUCCESS:
      nextState = {
        ...state,
        preStart: action.preStart,
        lessons: action.lessons
      };
      break;
    case types.ADD_INTRO_VIDEO_ERROR:
      nextState = { ...state, introVideoError: action.error || 'Error' };
      break;

    case types.CLEAR_INTRO_VIDEO_ERROR:
      nextState = { ...state, introVideoError: undefined };
      break;

    case types.RESOLVE_TOUR_VIDEO:
      nextState = { ...state, tourVideo: action.tourVideo };
      break;

    case types.RESOLVE_WELCOME_VIDEO:
      nextState = { ...state, welcomeVideo: action.welcomeVideo };
      break;

    case types.RESOLVE_LESSON_VIDEOS:
      nextState = { ...state, lessons: action.lessons };
      break;
  }

  return nextState;
};

function loadPreStart(userProgramId) {
  return async (dispatch, getState, { api }) => {
    const preStart = await api.loadPreStart(userProgramId);
    const lessons = await resolveLessonVideos(preStart && preStart.lessons);
    return dispatch(loadPreStartSuccess(preStart, lessons));

    // ----------------------------

    function resolveLessonVideos(lessons) {
      return Promise.all(
        (lessons || []).map(async lesson => ({
          ...lesson,
          videos: await Promise.all(
            lesson.videos.map(async video => ({
              ...video,
              url: await api.getContentBucketDownloadUrl({
                name: video.videoUrl
              }),
              showVideo,
              dispatch
            }))
          )
        }))
      );
    }
  };
}

function loadPreStartSuccess(preStart, lessons) {
  return { type: types.LOAD_PRESTART_SUCCESS, preStart, lessons };
}

function loadPreStartFailure(error) {
  return { type: types.LOAD_PRESTART_FAILURE, error };
}

function saveAvatar(userId, avatar) {
  return (dispatch, getState, { api }) => {
    return api.user.updateAvatar(userId, avatar).then(changes => {
      dispatch({
        type: actionType.updateUser,
        changes
      });
    });
  };
}

function removeAvatar(userId) {
  return (dispatch, getState, { api }) => {
    return api.user.updateUser(userId, { avatar: null }).then(() => {
      dispatch({
        type: actionType.updateUser,
        changes: {
          avatar: null
        }
      });
    });
  };
}

function updateBio(userId, shortBio) {
  return (dispatch, getState, { api }) => {
    return api.user.updateUser(userId, { shortBio }).then(() => {
      dispatch({
        type: actionType.updateUser,
        changes: {
          shortBio
        }
      });
    });
  };
}

function showVideo(title, video) {
  return relatedVideoActions.showPlayer(title, video);
}

function startIntroVideoUpload(video /*, payload, onProgress*/) {
  // console.info('startIntroVideoUpload', arguments);
  return (dispatch, getState, { api }) => {
    const fullState = getState();
    const uploadCreatorId = sclSelectors.getCurrentUserId(fullState);

    // add a record to the db, which can be shown in the ui
    api.user.addIntroVideo({
      id: uploadCreatorId,
      status: 'Uploading'
    });

    return dispatch(
      uploadActions.startUpload({
        sourceFile: video.blob,
        fileType: 'VIDEO',
        uploadOwnerId: uploadCreatorId,
        uploadType: UPLOAD_TYPE.INTRO_VIDEO
      })
    )
      .then(uploadRecord => api.user.finalizeIntroVideo(uploadRecord))
      .then(introVideo =>
        dispatch({
          type: actionType.updateUser,
          changes: { introVideo }
        })
      )
      .catch(err => {
        api.user.restoreIntroVideo(uploadCreatorId);
        if (err instanceof UploadCanceledError) {
          // ignore, cancel is not an error
        } else {
          logAndSendError(err, 'error uploading intro video');
          dispatch({ type: types.ADD_INTRO_VIDEO_ERROR, error: err });
        }
      });
  };
}

function cancelIntroVideoUpload() {
  return dispatch => {
    dispatch(uploadActions.cancelUpload());
  };
}

function removeIntroVideo() {
  return (dispatch, getState, { api }) => {
    const state = getState();
    const userId = sclSelectors.getCurrentUserId(state);
    return api.user.removeIntroVideo(userId).then(result =>
      dispatch({
        type: actionType.UPDATE_ENTITY_USER,
        id: userId,
        payload: { introVideo: null }
      })
    );
  };
}

function testWebcam() {
  return () => {
    window.open(
      'https://tokbox.com/developer/tools/precall/results',
      'webcam-test'
    );
  };
}

function openAccountDetails() {
  return () => {
    window.open(
      'https://tokbox.com/developer/tools/precall/results',
      'webcam-test'
    );
  };
}

function clearIntroVideoError() {
  return { type: types.CLEAR_INTRO_VIDEO_ERROR };
}

function resolveTourVideo() {
  return (dispatch, getState, { api }) => {
    const state = getState();
    const resolveAssetFn = makeResolveAssetFn(api);
    const video = getPreStartSource(state, 'tourVideo');
    if (!video) {
      return;
    }

    const tourVideoThumbnailName = getPreStartSource(
      state,
      'tourVideoThumbnailUrl'
    );

    // console.log('video', video);
    return resolveAssetFn(
      tourVideoThumbnailName || video.baseUrl + video.thumbnailUrl
    ).then(thumbnailUrl => {
      // console.log('thumbnailUrl', thumbnailUrl)
      return dispatch({
        type: types.RESOLVE_TOUR_VIDEO,
        tourVideo: {
          video,
          thumbnailUrl
        }
      });
    });
  };
}

function resolveWelcomeVideo() {
  return (dispatch, getState, { api }) => {
    const state = getState();
    const video = getPreStartSource(state, 'welcomeVideo');
    if (!video) {
      return;
    }
    // console.log('video', video);
    const thumbnailUrl =
      resolveContentImage(
        getPreStartSource(state, 'welcomeVideoThumbnailUrl')
      ) || video.baseUrl + video.thumbnailUrl;
    // console.log('thumbnailUrl', thumbnailUrl);
    return dispatch({
      type: types.RESOLVE_WELCOME_VIDEO,
      welcomeVideo: {
        video,
        thumbnailUrl
      }
    });
  };
}

export const actions = {
  loadPreStart,
  loadPreStartSuccess,
  loadPreStartFailure,
  cancelIntroVideoUpload,
  clearIntroVideoError,
  removeAvatar,
  removeIntroVideo,
  resolveTourVideo,
  resolveWelcomeVideo,
  saveAvatar,
  showVideo,
  startIntroVideoUpload,
  testWebcam,
  openAccountDetails,
  updateBio
};

const getPreStartSource = (state, prop = false, defaultValue = false) => {
  const preStart = state.dashboard.preStart.preStart;
  // console.log('preStartSource', 'preStart', preStart);
  if (!preStart) {
    return defaultValue;
  }
  if (prop) {
    const result = get(preStart, prop, defaultValue);
    // console.log('preStartSource',prop, result);
    return result;
  }

  return preStart;
};

const getPreStart = (state, prop = false, defaultValue = false) => {
  if (prop) {
    const result = get(state, `dashboard.preStart.${prop}`, defaultValue);
    // console.log('preStart',prop, result);
    return result;
  }

  const preStart = get(state, `dashboard.preStart`, defaultValue);
  // console.log('preStart', preStart);
  return preStart;
};

export const selectors = {
  getPreStartSource: state => getPreStartSource(state),
  getIntroText: state => getPreStartSource(state, 'introText'),
  getIntroVideoError: state => getPreStart(state, 'introVideoError'),
  getLessons: state => getPreStartSource(state, 'lessons'),
  getTourVideoSource: state => getPreStartSource(state, 'tourVideo'),
  getTourVideo: state => getPreStart(state, 'tourVideo'),
  getResolvedLessons: state => getPreStart(state, 'lessons'),
  getWelcomeVideo: state => getPreStart(state, 'welcomeVideo')
};
