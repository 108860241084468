import { findAsset } from '../../../common/assetUtils';

export function makeResolveAssetFn(api) {
  return async function resolveAsset(assetUrl) {
    if (assetUrl && assetUrl.indexOf('http') === 0) {
      return assetUrl;
    }

    let asset = findAsset(assetUrl);
    if (asset) {
      let downloadUrl = await api.getContentBucketDownloadUrl(asset);
      return downloadUrl;
    }
    return undefined;
  };
}

export async function resolveAsset(asset, api) {
  let downloadUrl = await api.getContentBucketDownloadUrl(asset);
  return downloadUrl;
}
