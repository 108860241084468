import capitalize from 'utils/capitalize';
import { getComment } from 'smashcut-client-lib/selectors';
import { actionType } from 'smashcut-client-lib/constants';
import fountain from '../components/FountainView/fountain.js';
import _ from 'lodash';

const prefix = 'SCREENPLAY_VIEW';

export const types = {
  SCREENPLAY_LOADED: `${prefix}/SCREENPLAY_LOADED`,
  SCREENPLAY_RENDERED: `${prefix}/SCREENPLAY_RENDERED`,
  SCROLL_TO_CHUNK: `${prefix}/SCROLL_TO_CHUNK`,
  SELECT_CHUNK: `${prefix}/SELECT_CHUNK`,
  SELECT_NEXT_CHUNK: `${prefix}/SELECT_NEXT_CHUNK`,
  SELECT_PREV_CHUNK: `${prefix}/SELECT_PREV_CHUNK`,
  START_ADDING_COMMENT: `${prefix}/START_ADDING_COMMENT`,
  STOP_ADDING_COMMENT: `${prefix}/STOP_ADDING_COMMENT`
};

export const initialState = {};

export default (state = initialState, action, fullState) => {
  let nextState = state;

  const selectChunk = (state, fullState, chunkIndex) => {
    if (_.isUndefined(chunkIndex) || _.isUndefined(state.selectableChunks)) {
      return {
        ...state,
        selectedChunkIndex: undefined,
        selectedChunk: undefined,
        hasNext: false,
        hasPrev: false
      };
    }
    const chunkIndexesWithComments = (fullState.listOfComments || []).map(
      cId => getComment(fullState, cId).chunkIndex
    );

    const hasNext = !_.isUndefined(
      chunkIndexesWithComments.find(idx => idx > chunkIndex)
    );
    const hasPrev = !_.isUndefined(
      chunkIndexesWithComments.find(idx => idx < chunkIndex)
    );
    return {
      ...state,
      selectedChunkIndex: chunkIndex,
      selectedChunk: state.selectableChunks[chunkIndex],
      hasNext,
      hasPrev
    };
  };

  switch (action.type) {
    case types.SCREENPLAY_LOADED:
      try {
        const output = fountain.parse(action.screenplay);
        const { title_page, pages, selectableChunks } = output.html;
        const { title } = output;

        nextState = {
          ...state,
          title,
          titlePage: title_page,
          pages,
          selectableChunks
        };
      } catch (e) {
        console.log('error loading screeplay', e);
      }
      break;

    case types.START_ADDING_COMMENT:
      nextState = {
        ...state,
        addingComment: true,
        addingCommentFor: { index: action.index }
      };
      break;

    case types.STOP_ADDING_COMMENT:
      nextState = {
        ...state,
        addingComment: false
      };
      break;

    case actionType.gotoComment:
      const comments = fullState.listOfComments.map(cId =>
        getComment(fullState, cId)
      );
      const selectedComment = comments.find(c => c.id == action.id);
      if (selectedComment) {
        nextState = {
          ...state,
          shouldScrollToChunk: selectedComment.chunkIndex
        };
      }

      break;

    case types.SELECT_CHUNK:
      const allComments = fullState.listOfComments.map(cId =>
        getComment(fullState, cId)
      );
      const hasComment = allComments.find(c => c.chunkIndex == action.index);
      const addingComment =
        !_.isUndefined(action.index) && _.isUndefined(hasComment);
      nextState = {
        ...selectChunk(state, fullState, action.index),
        addingComment,
        addingCommentFor: { index: action.index }
      };
      break;

    case types.SELECT_PREV_CHUNK:
    case types.SELECT_NEXT_CHUNK:
      const isNext = action.type == types.SELECT_NEXT_CHUNK;
      const chunkIndexesWithComments = _(fullState.listOfComments)
        .map(cId => getComment(fullState, cId).chunkIndex)
        .uniq()
        .sort((a, b) => (isNext ? a - b : b - a))
        .value();
      const newChunkIndex = _.find(chunkIndexesWithComments, idx =>
        isNext ? idx > state.selectedChunkIndex : idx < state.selectedChunkIndex
      );
      if (!_.isNil(newChunkIndex)) {
        nextState = selectChunk(state, fullState, newChunkIndex);
      }
      break;

    case types.SCROLL_TO_CHUNK:
      if (action.isReplay) {
        nextState = {
          ...state,
          scrollToChunkIndex: action.scrollToChunkIndex
        };
      }
      break;
  }
  return nextState;
};

const selectChunk = index => ({
  type: types.SELECT_CHUNK,
  index
});

const selectNextChunk = () => ({
  type: types.SELECT_NEXT_CHUNK
});

const selectPrevChunk = () => ({
  type: types.SELECT_PREV_CHUNK
});

const screenplayLoaded = screenplay => ({
  type: types.SCREENPLAY_LOADED,
  screenplay
});

const screenplayRendered = () => ({
  type: types.SCREENPLAY_RENDERED
});

const startAddingComment = index => ({
  type: types.START_ADDING_COMMENT,
  index
});

const stopAddingComment = () => ({
  type: types.STOP_ADDING_COMMENT
});

const scrollToChunk = scrollToChunkIndex => ({
  type: types.SCROLL_TO_CHUNK,
  scrollToChunkIndex
});

export const actions = {
  screenplayLoaded,
  screenplayRendered,
  scrollToChunk,
  selectChunk,
  selectNextChunk,
  selectPrevChunk,
  startAddingComment,
  stopAddingComment
};

const getScreenplayView = state => state.screenplayView;
const getTitle = state => state.screenplayView.title;
const getTitlePage = state => state.screenplayView.titlePage;
const getPages = state => state.screenplayView.pages;
const getChunks = state => state.screenplayView.selectableChunks;
const getSelectedChunkIndex = state => state.screenplayView.selectedChunkIndex;
const getSelectedChunk = state => state.screenplayView.selectedChunk;
const getShouldScrollToChunk = state =>
  state.screenplayView.shouldScrollToChunk;
const getHasNext = state => state.screenplayView.hasNext;
const getHasPrev = state => state.screenplayView.hasPrev;
const getTypeForClass = classType => {
  const types = {
    'scene-heading': 'Scene Heading'
  };
  return types[classType] || capitalize(classType || '');
};
const getScrollToChunkIndex = state => state.screenplayView.scrollToChunkIndex;

export const selectors = {
  getChunks,
  getHasNext,
  getHasPrev,
  getPages,
  getScreenplayView,
  getScrollToChunkIndex,
  getSelectedChunk,
  getSelectedChunkIndex,
  getShouldScrollToChunk,
  getTitle,
  getTitlePage,
  getTypeForClass
};
