// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LoginPageLogo--loginPageLogo--2heRGpze{margin-top:30px}.LoginPageLogo--loginPageLogo--2heRGpze img{width:300px}@media (max-width: 1000px){.LoginPageLogo--loginPageLogo--2heRGpze{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;margin:60px 0}.LoginPageLogo--loginPageLogo--2heRGpze img{width:360px;-o-object-fit:contain;object-fit:contain}}@media (max-width: 500px){.LoginPageLogo--loginPageLogo--2heRGpze img{width:270px;-o-object-fit:contain;object-fit:contain}}\n", ""]);
// Exports
exports.locals = {
	"loginPageLogo": "LoginPageLogo--loginPageLogo--2heRGpze"
};
module.exports = exports;
