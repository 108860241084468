import Img from 'react-image';
import Logo from './uoc_logo.png';
import React from 'react';
import styles from './CenteredLogo.scss';

const CenteredLogo = ({ children }) => (
  <div className={styles.centeredLogo}>
    <div className={styles.logoBackground}>
      <Img src={Logo} className={styles.logoImage} alt="UOC Logo" />
    </div>
    {children}
  </div>
);

export default CenteredLogo;
